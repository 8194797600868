import React, { useState, useEffect, useRef } from 'react';
import { useScramble } from "use-scramble";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import * as FaIcons from "react-icons/fa";
import * as CiIcons from "react-icons/ci";
import KeyboardShibaLeft from '../assets/keyboard-shiba-left.png';
import KeyboardShibaRight from '../assets/keyboard-shiba-right.png';
import Capstone from '../assets/capstone.png';
import Suisnails from '../assets/suisnails.png';

const Home = () => {
  // ===== Scramble Text =====
  
  const texts = ["Interactive Websites", "Scalable Applications", "Effective IT Solutions"];
  const [index, setIndex] = useState(0);
  
  const { ref, replay } = useScramble({
    text: texts[index],
    speed: 0.5,
    step: 1,
    delay: 0,
  });

  const footerRef = useRef(null);
  const servicesRef = useRef(null);

  const scrollToFooter = () => {
    footerRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToServices = () => {
    servicesRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 4000);
    
    replay();
    
    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [index, replay, texts.length]);

  return (
    <div className='home flex flex-col w-screen h-auto text-white bg-inu_orange bg-cover font-anton text-3xl'>
      <Navbar />
      <div className='flex flex-col justify-center items-center px-4'>
        <div className='text-center text-4xl sm:text-6xl mt-8 sm:mt-16'>
          <h1 className="drop-shadow">Helping businesses to succeed by developing</h1>
          <h1 className="gradient-text drop-shadow bg-gradient-to-r from-blue-100 to-blue-200 inline-block text-transparent bg-clip-text" ref={ref}>''</h1>
        </div>
        <div className='buttons flex my-8 sm:mb-32'>
          <button
            onClick={scrollToFooter}
            className='flex items-center border-2 rounded-3xl border-solid border-white py-1 px-4 sm:py-2 sm:px-8 bg-inu_darkorange text-2xl sm:text-4xl mr-1 sm:mr-4 hover:bg-[#FF905D]'
          >
            <FaIcons.FaPaw className='mr-2'/>Get a Free Quote
          </button>
          <button
            onClick={scrollToServices}
            className='border-2 rounded-3xl border-solid border-white py-1 px-4 text-2xl sm:text-4xl hover:bg-inu_lightorange'
          >
            Learn More
          </button>
        </div>
      </div>
      <div ref={servicesRef} className='flex items-center flex-col bg-inu_darkorange w-full px-8'>
        <img src={KeyboardShibaLeft} alt='keyboardshibaleft' className='sm:w-[500px] sm:h-[200px] w-40 h-16 mt-4'></img>
        <h1 className="text-4xl sm:text-6xl my-4 text-center"><span className='text-blue-200'>Dev & IT </span> Services</h1>
        <p className='font-sans font-light text-xl sm:text-3xl text-center lg:mx-40'>At Inutech, we pride ourselves on our speed and loyalty, working with you every step of the way to help you meet your IT needs.</p>
        <div className='md:flex md:my-8 lg:mx-40'>
          <div className='py-1 px-4 border-fadedwhite border-2 border-solid rounded-3xl text-xl basis-1/2 m-2'>
            <h1 className='flex items-center font-sans font-light text-xl sm:text-2xl sm:text-2xl mb-1 border-b-2 border-fadedwhite'><CiIcons.CiGlobe className='mr-2 text-blue-200' />Web Development</h1>
            <p className='font-sans font-light italic text-lg sm:text-2xl'>Quick & pain-free website design, development, testing, optimization.</p>
          </div>
          <div className='py-1 px-4 border-fadedwhite border-2 border-solid rounded-3xl text-xl basis-1/2 m-2'>
            <h1 className='flex items-center font-sans font-light text-xl sm:text-2xl mb-1 border-b-2 border-fadedwhite'><CiIcons.CiMobile1 className='mr-2 text-blue-200' />Mobile App</h1>
            <p className='font-sans font-light italic text-lg sm:text-2xl'>Design, build, optimize, expand applications on android and/or iOS.</p>
          </div>
          <div className='py-1 px-4 border-fadedwhite border-2 border-solid rounded-3xl text-xl basis-1/2 m-2'>
            <h1 className='flex items-center font-sans font-light text-xl sm:text-2xl mb-1 border-b-2 border-fadedwhite'><CiIcons.CiHeadphones className='mr-2 text-blue-200' />IT Consulting</h1>
            <p className='font-sans font-light italic text-lg sm:text-2xl'>Professional analysis and feasible recommendations; managed services.</p>
          </div>
        </div>
      </div>
      <div className='flex items-center flex-col w-full px-8'>
        <img src={KeyboardShibaRight} alt='keyboardshibaright' className='sm:w-[500px] sm:h-[200px] w-40 h-16 mt-4'></img>
        <h1 className="text-4xl sm:text-6xl my-4 text-center">About <span className='text-blue-200'>Us </span></h1>
        <p className='font-sans font-light text-xl sm:text-3xl text-center lg:mx-40'>Inutech is a small Vancouver-based IT company run by Han. The following is a non-exhaustive list of our past projects:</p>
        <div className='flex'>
          <div className='relative text-2xl basis-1/2 mt-4 mr-2 overflow-hidden group mb-8 max-w-72 xl:max-w-[400px]'>
            <img src={Capstone} alt='capstone' className='w-full h-full object-cover group-hover:brightness-50 transition duration-300 rounded-3xl'></img>
            <a
              href="https://learn.inutech.ca/login"
              target="_blank"
              rel="noopener noreferrer"
              className="absolute inset-0 flex items-center justify-center font-sans font-light text-2xl text-center text-white opacity-0 group-hover:opacity-100 transition duration-300"
            >
              NPC Capstone
            </a>
          </div>
          <div className='relative text-2xl basis-1/2 mt-4 mr-2 overflow-hidden group mb-8 max-w-72 xl:max-w-[400px]'>
            <img src={Suisnails} alt='suisnails' className='w-full h-full object-cover group-hover:brightness-50 transition duration-300 rounded-3xl'></img>
            <a
              href="https://www.suisnails.io"
              target="_blank"
              rel="noopener noreferrer"
              className="absolute inset-0 flex items-center justify-center font-sans font-light text-2xl text-center text-white opacity-0 group-hover:opacity-100 transition duration-300"
            >
              Sui Snails
            </a>
          </div>
        </div>
      </div>
      <div ref={footerRef}>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
